import {connect} from "react-dynadux";

import {ETKAppCore} from "server-app/dist/interfaces";

import {
  ButtonBar,
  EButtonBarAlign,
} from "ui-components/dist/ButtonBar";
import {
  Button,
  EButtonSize,
} from "ui-components/dist/Button";
import {IAppStore} from "../../../state";

import {DynaCMSIntlProvider} from "../../dyna-cms/public-components/DynaCMSIntlProvider";
import {
  DynaIntlTranslate,
} from "../../dyna-intl/components-public";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";

export interface IGoHomeButtonProps {
  store: IAppStore;
  inline?: boolean;
}

export const GoBackHomeButtons = connect((props: IGoHomeButtonProps): JSX.Element => {
  const {
    store: {app: {actions: {navigateTo}}},
    inline,
  } = props;

  return (
    <DynaCMSIntlProvider tks={tks}>
      <ButtonBar
        inline={inline}
        align={EButtonBarAlign.CENTER}
      >
        <Button
          icon={<ArrowBackIosIcon/>}
          size={EButtonSize.LARGE}
          onClick={() => navigateTo({url: '<'})}
        >
          <DynaIntlTranslate
            tk={tks.back}
            default="Back"
          />
        </Button>
        <Button
          icon={<HomeIcon/>}
          size={EButtonSize.LARGE}
          onClick={() => navigateTo({url: '/'})}
        >
          <DynaIntlTranslate
            tk={tks.home}
            default="Home"
          />
        </Button>
      </ButtonBar>
    </DynaCMSIntlProvider>
  );
});

const tks = {
  back: ETKAppCore.GO_BACK,
  home: ETKAppCore.MAIN_PAGE,
};
