import {IAppRoute} from "../../application/config/IAppRoute";

import {EDynaIntlUserRights} from "server-app/dist/interfaces";

import {DynaIntlTranslationsPage} from "../pages/DynaIntlTranslationsPage";

import {createIcon} from "ui-components/dist/IconComponent";
import {TranslationFeatureIcon} from "../assets/icons";

export const routeDynaIntlTranslationsEditor: IAppRoute<{ serializedFilterSort: string }> = {
  menuId: '52158289638210',
  Icon: createIcon.byMuiIcon(TranslationFeatureIcon),
  title: 'Translations',
  subtitle: "General translation system",
  description: "Translate messages from entire application for various langauges and countries",
  routePath: '/dyna-intl/translations/:serializedFilterSort?',
  getRoutePath: ({serializedFilterSort} = {serializedFilterSort: ""}) =>
    routeDynaIntlTranslationsEditor
      .routePath
      .replace(':serializedFilterSort', serializedFilterSort),
  userHasAnyOfRights: [
    EDynaIntlUserRights.VIEW_TRANSLATIONS,
    EDynaIntlUserRights.EDIT_CONFIGURATION,
  ],
  userHasAllRights: [],
  render: ({pathParams: {serializedFilterSort}}) => {
    return <DynaIntlTranslationsPage serializedFilterSort={serializedFilterSort}/>;
  },
};
