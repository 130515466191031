import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlLocalePickerProps} from "./DynaIntlLocalePicker";
import {TObject} from "utils-library/dist/typescript";

const DynaIntlLocalePickerLazy: any = lazy(() => import("./DynaIntlLocalePicker"));

export const DynaIntlLocalePicker = <TData extends TObject = any, >(props: IDynaIntlLocalePickerProps<TData
>) => (
    <LazyLoadComponent>
      <DynaIntlLocalePickerLazy {...props}/>
    </LazyLoadComponent>
  );
