import {connect} from "react-dynadux";
import {dynaSwitch} from "dyna-switch";

import {
  ThemeProvider,
  EThemeName,
} from "ui-components/dist/ThemeProvider";

import {IAppStore} from "../../../state";
import {AppRouterSwitch} from "./AppRouterSwitch";

import {appConfig} from "../config/appConfig";
import {EAppTheme} from "../state/appSection";
import {themeChange} from "./themeChange";

export interface IAppWithStyleProps {
  store: IAppStore;
}

export const AppWithStyle = connect((props: IAppWithStyleProps): JSX.Element => {
  const {
    theme: themeName,
    themeSize,
  } = props.store.app.state;

  const muiThemeName = dynaSwitch<EThemeName, EAppTheme>(
    themeName,
    EThemeName.MUI5_LIGHT,
    {
      [EAppTheme.LIGHT]: appConfig.lightTheme,
      [EAppTheme.DARK]: appConfig.darkTheme,
    },
  );

  return (
    <ThemeProvider
      themeName={muiThemeName}
      themeSize={themeSize}
      themeChange={themeChange}
    >
      <AppRouterSwitch/>
    </ThemeProvider>
  );
});
