import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlProviderProps} from "./DynaIntlProvider";

const DynaIntlProviderLazy = lazy(() => import("./DynaIntlProvider"));

export const DynaIntlProvider = (props: IDynaIntlProviderProps) => (
  <LazyLoadComponent>
    <DynaIntlProviderLazy {...props}/>
  </LazyLoadComponent>
);
