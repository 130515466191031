import {getCompanyElements} from "../../../../companies";

import {routeBackOfficePagePaths} from "../../routes/routeBackOfficePage.paths";
import {routeDynaCMSMainPagePaths} from "../../../dyna-cms/routes/routeDynaCMSMainPage.paths";
import {routeListUsersPagePaths} from "../../../user-management/routes/routeListUsersPage.paths";
import {routeDynaIntlTranslationsEditor} from "../../../dyna-intl/routes/routeDynaIntlTranslationsEditor";
import {routeAppSettingsPage} from "../../routes/routeAppSettingsPage";

import {IAppMenu} from "../IAppMenu";
import {convertRouteToAppMenu} from "../IAppMenu";

/**
 * Gathers all available options of the application
 *
 * # Do not use it directly
 * All available (according to user's right) menu options are in the store!
 */
export const getAppMenusMain = async (): Promise<Array<IAppMenu | "DIVIDER">> => {
  const appMenusMain: (IAppMenu | "DIVIDER")[] = [];
  const {menus} = await getCompanyElements();

  menus.forEach(menu => appMenusMain.push(menu));

  appMenusMain.push("DIVIDER");

  appMenusMain.push(
    convertRouteToAppMenu(
      routeBackOfficePagePaths, // Menu id##backoffice
      {
        children: [
          // These children are added, but the menu might not show the nested options
          convertRouteToAppMenu(routeDynaCMSMainPagePaths),
          convertRouteToAppMenu(routeListUsersPagePaths),
          convertRouteToAppMenu(routeDynaIntlTranslationsEditor),
          convertRouteToAppMenu(routeAppSettingsPage),
        ],
      },
    ),
  );

  return appMenusMain;
};
