import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconViewer } from "../IconViewer";
/**
 * UnsortedList renders ul & li cleaning the layout styles
 */
export const UnsortedList = (props) => {
    const { inline = false, IconBullet, children, } = props;
    return (_jsx("ul", { style: {
            margin: 0,
            padding: 0,
        }, children: (Array.isArray(children) ? children : [children])
            .map((child, index) => (_jsxs("li", { style: {
                listStyleType: 'none',
                display: inline ? 'inline' : undefined,
            }, children: [_jsx(IconViewer, { Icon: IconBullet, sx: {
                        mr: 0.5,
                        position: 'relative',
                        top: 2,
                    } }), child] }, index))) }));
};
