import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlCountryPickerProps} from "./DynaIntlCountryPicker";
import {TObject} from "utils-library/dist/typescript";

const DynaIntlCountryPickerLazy: any = lazy(() => import("./DynaIntlCountryPicker"));

export const DynaIntlCountryPicker = <TData extends TObject = any,>(props: IDynaIntlCountryPickerProps<TData>) => (
  <LazyLoadComponent>
    <DynaIntlCountryPickerLazy {...props}/>
  </LazyLoadComponent>
);
