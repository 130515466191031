import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { ThemeProvider as MUI5ThemeProvider, createTheme as muiCreateTheme, } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { GlobalScrollbars } from "./components/GlobalScrollbars";
import { MUIV5_PALETTE_LIGHT } from "./palettes/MUIV5_PALETTE_LIGHT";
import { MUIV5_PALETTE_DARK } from "./palettes/MUIV5_PALETTE_DARK";
import { MUIV4_PALETTE_LIGHT } from "./palettes/MUIV4_PALETTE_LIGHT";
import { MUIV4_PALETTE_DARK } from "./palettes/MUIV4_PALETTE_DARK";
import { FASHIONX_PALETTE_LIGHT } from "./palettes/FASHIONX_PALETTE_LIGHT";
import { FASHIONX_PALETTE_DARK } from "./palettes/FASHIONX_PALETTE_DARK";
export var EThemeName;
(function (EThemeName) {
    EThemeName["MUI4_LIGHT"] = "MUI4_LIGHT";
    EThemeName["MUI4_DARK"] = "MUI4_DARK";
    EThemeName["MUI5_LIGHT"] = "MUI5_LIGHT";
    EThemeName["MUI5_DARK"] = "MUI5_DARK";
    EThemeName["FASHIONX_LIGHT"] = "FASHIONX_LIGHT";
    EThemeName["FASHIONX_DARK"] = "FASHIONX_DARK";
})(EThemeName || (EThemeName = {}));
export var EThemeSize;
(function (EThemeSize) {
    EThemeSize["XSMALL"] = "XSMALL";
    EThemeSize["SMALL"] = "SMALL";
    EThemeSize["MEDIUM"] = "MEDIUM";
    EThemeSize["LARGE"] = "LARGE";
    EThemeSize["XLARGE"] = "XLARGE";
})(EThemeSize || (EThemeSize = {}));
const themeSizes = {
    [EThemeSize.XSMALL]: {
        spacing: 4,
        fontSize: 12,
    },
    [EThemeSize.SMALL]: {
        spacing: 6,
        fontSize: 13,
    },
    [EThemeSize.MEDIUM]: {
        spacing: 8,
        fontSize: 14,
    },
    [EThemeSize.LARGE]: {
        spacing: 8,
        fontSize: 16,
    },
    [EThemeSize.XLARGE]: {
        spacing: 10,
        fontSize: 18,
    },
};
export const createTheme = ({ themeName, themeSize, }) => {
    const muiMode = themeName.split('_').pop()
        .toLocaleLowerCase();
    const { spacing, fontSize, } = themeSizes[themeSize];
    const theme = muiCreateTheme({
        palette: { mode: muiMode },
        spacing,
        typography: { fontSize },
    });
    theme.palette = Object.assign(Object.assign({}, theme.palette), {
        [EThemeName.MUI5_LIGHT]: MUIV5_PALETTE_LIGHT,
        [EThemeName.MUI5_DARK]: MUIV5_PALETTE_DARK,
        [EThemeName.MUI4_LIGHT]: MUIV4_PALETTE_LIGHT,
        [EThemeName.MUI4_DARK]: MUIV4_PALETTE_DARK,
        [EThemeName.FASHIONX_LIGHT]: FASHIONX_PALETTE_LIGHT,
        [EThemeName.FASHIONX_DARK]: FASHIONX_PALETTE_DARK,
    }[themeName]);
    const getRem = (rem) => `${fontSize * rem}px`;
    theme.typography.fontSize = fontSize;
    theme.typography.htmlFontSize = fontSize;
    theme.typography.h1.fontSize = getRem(2);
    theme.typography.h1.fontWeight = "bold";
    theme.typography.h2.fontSize = getRem(1.8);
    theme.typography.h2.fontWeight = "normal";
    theme.typography.h3.fontSize = getRem(1.4);
    theme.typography.h3.fontWeight = "bold";
    theme.typography.h4.fontSize = getRem(1.3);
    theme.typography.h4.fontWeight = "normal";
    theme.typography.h5.fontSize = getRem(1.2);
    theme.typography.h5.fontWeight = "bold";
    theme.typography.h6.fontSize = getRem(1.1);
    theme.typography.h6.fontWeight = "normal";
    theme.typography.body1.fontSize = getRem(0.9);
    theme.typography.body1.lineHeight = "";
    theme.typography.body1.marginBottom = "";
    theme.typography.body2.fontSize = getRem(0.8);
    theme.typography.body2.lineHeight = "";
    theme.typography.body2.marginBottom = "";
    theme.typography.button.textTransform = "none";
    theme.components = {
        MuiUseMediaQuery: {
            defaultProps: {
                defaultMatches: true,
                // Fixes the problem that on start up the MuiUseMediaQuery doesn't recognize the initial breakpoint
                // For more: https://github.com/chakra-ui/chakra-ui/issues/3124
                noSsr: true,
            },
        },
    };
    return theme;
};
export const ThemeProvider = (props) => {
    const { themeName = EThemeName.MUI4_LIGHT, themeSize = EThemeSize.SMALL, themeChange, children, } = props;
    const theme = createTheme({
        themeName,
        themeSize,
    });
    useEffect(() => {
        themeChange && themeChange(themeName, theme);
    }, [themeName]);
    return (_jsxs(MUI5ThemeProvider, { theme: theme, children: [_jsx(CssBaseline, {}), _jsx(GlobalScrollbars, {}), children] }));
};
