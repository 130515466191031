import {IAppStore} from "../../../../state";

import {ArticleViewerEva} from "../../components/article-viewers/ArticleViewerEva";

export interface IDynaCMSArticleViewerMultiLanguageProps {
  store: IAppStore;
  multiLangArticles: {
    countryId: string;
    languageId: string;
    groupId: string;
    articleId: string;
  }[];
  showHeader?: boolean;
}

export const DynaCMSArticleViewerMultiLanguage = (props: IDynaCMSArticleViewerMultiLanguageProps): JSX.Element => {
  const {
    store: {
      dynaCMS: {
        state: {
          countryIds: envCountriesIds,
          languageId: envLanguageId,
        },
      },
    },
    multiLangArticles,
    showHeader,
  } = props;

  const article =
    (() => {
      if (envCountriesIds.length) return envCountriesIds;
      return multiLangArticles.map(a => a.countryId);
    })()
      .map(countryId => ({
        countryId,
        languageId: envLanguageId,
      }))
      .map(
        (
          {
            countryId,
            languageId,
          },
        ) =>
          multiLangArticles.find(article => {
            return (
              article.countryId === countryId &&
              article.languageId === languageId
            );
          }),
      )
      .filter(Boolean)
      .shift();

  if (!article) {
    return (
      <div>
        <h1>Error C9248</h1>
        <p>No variation of article found for current countries selection: [{envCountriesIds.join()}] and current language: [${envLanguageId}]</p>
      </div>
    );
  }

  const {
    groupId,
    languageId,
    countryId,
    articleId,
  } = article;

  return (
    <ArticleViewerEva
      groupId={groupId}
      languageId={languageId}
      countryId={countryId}
      id={articleId}
      idType="articleId"
      loadPurpose="view"
      showHeader={showHeader}
    />
  );
};
