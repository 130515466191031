import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlDateFormatterProps} from "./DynaIntlDateFormatter";

const DynaIntlDateFormatterLazy = lazy(() => import("./DynaIntlDateFormatter"));

export const DynaIntlDateFormatter = (props: IDynaIntlDateFormatterProps) => (
  <LazyLoadComponent>
    <DynaIntlDateFormatterLazy {...props}/>
  </LazyLoadComponent>
);
