import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlTranslationsSettingsPageProps} from "./DynaIntlTranslationsSettingsPage";

const DynaIntlTranslationsSettingsPageLazy = lazy(() => import("./DynaIntlTranslationsSettingsPage"));

export const DynaIntlTranslationsSettingsPage = connect((props: IDynaIntlTranslationsSettingsPageProps) => (
  <LazyLoadComponent>
    <DynaIntlTranslationsSettingsPageLazy {...props}/>
  </LazyLoadComponent>
));
