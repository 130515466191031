import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlTranslationsPageProps} from "./DynaIntlTranslationsPage";

const DynaIntlTranslationsPageLazy = lazy(() => import("./DynaIntlTranslationsPage"));

export const DynaIntlTranslationsPage = connect((props: IDynaIntlTranslationsPageProps) => (
  <LazyLoadComponent>
    <DynaIntlTranslationsPageLazy {...props}/>
  </LazyLoadComponent>
));
