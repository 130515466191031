import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { IconViewer } from "../IconViewer";
export const IconText = (props) => {
    const { sx = {}, dataComponentName, show = true, inline, Icon, children, } = props;
    if (!show)
        return null;
    return (_jsxs(FlexContainerHorizontal, { sx: sx, inline: inline, dataComponentName: [dataComponentName, "IconText"], alignVertical: "top", children: [_jsx(FlexItemMin, { show: !!Icon, children: _jsx(IconViewer, { sx: {
                        marginTop: '0.1em',
                        marginRight: '0.2em',
                    }, Icon: Icon }) }), _jsx(FlexItemMax, { sx: { whiteSpace: 'normal' }, children: children })] }));
};
