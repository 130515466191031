import moment from "moment";
import {connect} from "react-dynadux";

import {IDynaCMSArticleHeading} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";

import {IAppStore} from "../../../../../../../../state";

import {IconText} from "ui-components/dist/IconText";

import {DynaCMSIntlProvider} from "../../../../../../public-components/DynaCMSIntlProvider";
import {DynaIntlDateTimeFormatter} from "../../../../../../../dyna-intl/components-public";

import {resolveShowDate} from "./resolveShowDate";

import {createIcon} from "ui-components/dist/IconComponent";
import CreateDateIcon from '@mui/icons-material/AccessTime';
import UpdateDateIcon from '@mui/icons-material/Update';
import NotPublishedIcon from '@mui/icons-material/PublicOff';

export interface IArticleDateViewerProps {
  store: IAppStore;
  articleHeading: IDynaCMSArticleHeading;
  target:
    | "list"
    | "view";
  show?: boolean;
  showIcon?: boolean;
  showUpdatedAt?: boolean;
}

export const ArticleDateViewer = connect((props: IArticleDateViewerProps): JSX.Element | null => {
  const {
    store: {
      dynaCMS: {
        state: {
          settings: {
            groups,
            defaultArticleShowPublishDateInList,
            defaultArticleShowUpdateDateInList,
            defaultArticleShowPublishDateInView,
            defaultArticleShowUpdateDateInView,
          },
        },
      },
    },
    articleHeading: {
      id: articleId,
      groupId,
      showPublishDateInList,
      showUpdateDateInList,
      showPublishDateInView,
      showUpdateDateInView,
      publishAt,
      updatedAt,
    },
    target,
    show = true,
    showIcon,
    showUpdatedAt = true,
  } = props;

  const group = groups.find(group => group.id === groupId);

  if (!publishAt && !!articleId) {
    return (
      <Box>
        <IconText
          Icon={createIcon.byMuiIcon(NotPublishedIcon)}
          sx={{fontSize: 14}}
        >
          Not published yet
        </IconText>
      </Box>
    );
  }

  if (!publishAt) return null;

  return (
    <DynaCMSIntlProvider>
      <Box
        sx={{opacity: 0.8}}
        dataComponentName="ArticleDateViewer"
        show={show}
      >
        <Box
          title="Published"
          show={
            resolveShowDate(
              target === "list" ? defaultArticleShowPublishDateInList : defaultArticleShowPublishDateInView,
              target === "list" ? group && group.showPublishDateInList : group && group.showPublishDateInView,
              target === "list" ? showPublishDateInList : showPublishDateInView,
            )
          }
        >
          <IconText
            Icon={showIcon ? createIcon.byMuiIcon(CreateDateIcon) : undefined}
            sx={{fontSize: 14}}
          >
            <DynaIntlDateTimeFormatter
              date={publishAt}
              day="numeric"
              month="long"
              order="date-time"
            />
          </IconText>
        </Box>
        <Box
          show={
            showUpdatedAt &&
            moment(updatedAt).diff(publishAt, 'minutes') > 3 &&
            resolveShowDate(
              target === "list" ? defaultArticleShowUpdateDateInList : defaultArticleShowUpdateDateInView,
              target === "list" ? group && group.showUpdateDateInList : group && group.showUpdateDateInView,
              target === "list" ? showUpdateDateInList : showUpdateDateInView,
            )
          }
          title="UPDATED"
        >
          <IconText
            Icon={createIcon.byMuiIcon(UpdateDateIcon)}
            sx={{fontSize: 14}}
          >
            <DynaIntlDateTimeFormatter
              date={updatedAt}
              day="numeric"
              month="long"
              order="date-time"
            />
          </IconText>
        </Box>
      </Box>
    </DynaCMSIntlProvider>
  );
});
