var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect, } from "react";
import { guid } from "dyna-guid";
import { dynaError, } from "dyna-error";
import { EProgressState } from "../ui-interfaces";
import { useIsMounted } from "../useIsMounted";
import { useOnChange } from "../useOnChange";
import { useEffectStartup } from "../useEffectStartup";
export const useLoadData = ({ defaultData, loadOnStartup = true, intervalReload, errorHandling = {}, reloadDep, load: loadMethod, onLoad, onChange, onError, }) => {
    const { consoleIt = false, consoleMessage = 'useLoadData: Load error', userMessage, } = errorHandling;
    const getIsMounted = useIsMounted();
    const [loadState, setLoadState] = useState(EProgressState.PENDING);
    const [isInitialLoading, setIsInitialLoading] = useState(loadOnStartup);
    const [isLoading, setIsLoading] = useState(loadOnStartup);
    const [isReloading, setIsReloading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(defaultData);
    const [error, setError] = useState(undefined);
    const [changeId, setChangeId] = useState("");
    useEffectStartup(() => {
        if (loadOnStartup)
            load().catch(() => undefined); // Swallow the error, nobody can catch it.
    });
    useEffect(() => {
        if (!intervalReload)
            return undefined;
        const intervalHandler = setInterval(() => load(true), intervalReload);
        return () => clearInterval(intervalHandler);
    }, [intervalReload]);
    useOnChange({
        dep: reloadDep,
        onChange: () => {
            setData(defaultData);
            onChange === null || onChange === void 0 ? void 0 : onChange(defaultData);
            load().catch(() => undefined); // Swallow the error, nobody can catch it.
        },
    });
    const load = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (performsReloading = false) {
        try {
            setError(undefined);
            if (performsReloading)
                setIsReloading(true);
            setLoadState(EProgressState.IN_PROGRESS);
            setIsLoading(true);
            const data = yield loadMethod();
            if (!getIsMounted())
                return data;
            setData(data);
            onChange === null || onChange === void 0 ? void 0 : onChange(data);
            if (performsReloading)
                setIsReloading(false);
            setLoadState(EProgressState.SUCCESS);
            setIsLoading(false);
            setIsInitialLoading(false);
            setLoaded(true);
            setChangeId(guid());
            onLoad === null || onLoad === void 0 ? void 0 : onLoad(data);
            return data;
        }
        catch (e) {
            const error = dynaError(e);
            if (userMessage)
                error.userMessage = userMessage;
            if (consoleIt)
                console.error(consoleMessage, error);
            if (getIsMounted()) {
                setLoadState(EProgressState.FAILED);
                performsReloading
                    ? setIsReloading(false)
                    : setIsLoading(false);
                if (isInitialLoading)
                    setIsInitialLoading(false);
                setError(error);
            }
            onError === null || onError === void 0 ? void 0 : onError(error);
            throw error;
        }
    });
    const reset = () => {
        setIsInitialLoading(false);
        setLoadState(EProgressState.PENDING);
        setIsLoading(false);
        setIsReloading(false);
        setLoaded(false);
        setData(defaultData);
        onChange === null || onChange === void 0 ? void 0 : onChange(defaultData);
        setError(undefined);
        setChangeId("");
    };
    return {
        isInitialLoading,
        isLoading,
        isReloading,
        loadState,
        loaded,
        data,
        error,
        changeId,
        load,
        reload: () => load(true),
        reset,
        changeData: payload => {
            setData(data => {
                const newData = typeof payload === "function"
                    ? payload(data)
                    : payload;
                const output = Object.assign(Object.assign({}, data), newData);
                onChange === null || onChange === void 0 ? void 0 : onChange(output);
                return output;
            });
            setChangeId(guid());
        },
    };
};
