import { useRef, useEffect, } from "react";
import { isInDevelopmentMode } from "utils-library/dist/web";
// Todo: After regressions evaluate if this hook is good or not -- 2025-03-18--13-35
/**
 * This hook works like the classic `useEffect`, but in development mode, it calls the callback only once.
 *
 * This fixes the issue in the development environment where hooks (and components) run twice due to React Strict Mode.
 *
 * **Warning:** This hasn't been fully tested yet. It's used by core methods like `useLoadData`.
 */
export const useEffectStartup = (effect) => {
    const renderCount = useRef(0);
    useEffect(() => {
        /**
         * Resolve whether to eat next of the 1st calls
         */
        const ignore = (isInDevelopmentMode
            && renderCount.current > 0);
        renderCount.current++;
        if (!ignore)
            effect();
    }, []);
};
