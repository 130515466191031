import { enumInfo } from "utils-library/dist/utils";
export var EDynaIntlUserRights;
(function (EDynaIntlUserRights) {
    EDynaIntlUserRights["VIEW_TRANSLATIONS"] = "DIT_V";
    EDynaIntlUserRights["EDIT_TRANSLATIONS"] = "DIT_E";
    EDynaIntlUserRights["EDIT_CONFIGURATION"] = "DIT_C";
})(EDynaIntlUserRights || (EDynaIntlUserRights = {}));
export const dynaIntlRightsEnumInfo = enumInfo({
    [EDynaIntlUserRights.VIEW_TRANSLATIONS]: {
        label: {
            id: 'man52093488311915',
            defaultMessage: "View translations",
            description: "",
        },
        description: {
            id: 'man52093488311916',
            defaultMessage: "View the translations of any feature",
        },
    },
    [EDynaIntlUserRights.EDIT_TRANSLATIONS]: {
        label: {
            id: 'man52093488311917',
            defaultMessage: "Edit translations",
            description: "",
        },
        description: {
            id: 'man52093488311918',
            defaultMessage: "Edit the translations of any feature",
        },
    },
    [EDynaIntlUserRights.EDIT_CONFIGURATION]: {
        label: {
            id: 'man52093488311919',
            defaultMessage: "Configure translations",
            description: "",
        },
        description: {
            id: 'man52093488311920',
            defaultMessage: "Configure translation system",
        },
    },
});
