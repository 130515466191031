import {IAppRoute} from "../config/IAppRoute";
import {routeAppVersion} from "./routeAppVersion";

import {routeAppSettingsPage} from "./routeAppSettingsPage";
import {routeBackOfficePage} from "./routeBackOfficePage";
import {routeBroadcasterConnectionTestPage} from "./routeBroadcasterConnectionTestPage";

export const routesAppCore: IAppRoute<any, any>[] = [
  routeAppVersion,
  routeAppSettingsPage,
  routeBackOfficePage,
  routeBroadcasterConnectionTestPage,
];
