import {EDynaIntlUserRights} from "server-app/dist/interfaces";
import {createIcon} from "ui-components/dist/IconComponent";
import {IAppRoute} from "../../application/config/IAppRoute";
import {DynaIntlTranslationsSettingsPage} from "../pages/DynaIntlTranslationsSettingsPage";
import {SettingsIcon} from "ui-components/dist/graphics-common-icons";

export const routeDynaIntlTranslationsSettings: IAppRoute = {
  Icon: createIcon.byMuiIcon(SettingsIcon),
  title: 'Translation Settings',
  subtitle: 'Configure the translation feature',
  description: 'Adjust available langauges and countries for current core company application',
  routePath: '/dyna-intl/settings',
  getRoutePath: () => routeDynaIntlTranslationsSettings.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDynaIntlUserRights.EDIT_CONFIGURATION,
  ],
  render: ()=><DynaIntlTranslationsSettingsPage/>,
};
