import {INotificationIcon} from "ui-components/dist/AppContainerDashboard";

import {IAppStore} from "../../../state";
import {themeNotificationIcon} from "./themeNotificationIcon";

export const appNotificationIcons = (store: IAppStore): INotificationIcon[] => {
  const notificationIcons: (INotificationIcon | null)[] = [];

  notificationIcons.push(themeNotificationIcon(store));

  return notificationIcons.filter(Boolean) as any;
};
