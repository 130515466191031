import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlTimeFormatterProps} from "./DynaIntlTimeFormatter";

const DynaIntlTimeFormatterLazy = lazy(() => import("./DynaIntlTimeFormatter"));

export const DynaIntlTimeFormatter = (props: IDynaIntlTimeFormatterProps) => (
  <LazyLoadComponent>
    <DynaIntlTimeFormatterLazy {...props}/>
  </LazyLoadComponent>
);
