import * as React from "react";
import {
  ReactElement,
  useMemo,
} from "react";

import {
  EViewWidthType,
  IDynaCMSArticle,
  IDynaCMSBlockMarkdown,
  getDefaultDynaCMSBlockHeader,
  getDefaultDynaCMSBlockQuote,
} from "server-app/dist/interfaces";

import {Box} from "ui-components/dist/Box";
import {MarkdownViewer} from "ui-components/dist/MarkdownViewer";
import {clearDoubleSpaces} from "utils-library/dist/utils";

import {IAppStore} from "../../../../../state";
import {
  DynaCMSFont,
  DynaCMSRelatedArticles,
  ERelatedArticlesFormat,
} from "../../../public-components";

import {ViewerHeading} from "../ViewerHeading";
import {ViewerQuote} from "../ViewerQuote";

import {getContentPaddingSx} from "../utils/getContentPadding";
import {sxHeadingLetter} from "../utils/sxHeadingLetter";

export interface IViewerMarkdownProps {
  store: IAppStore;
  article: IDynaCMSArticle;
  content: IDynaCMSBlockMarkdown;
  contentSpacing: number;
  viewWidthType?: EViewWidthType;
}

const RELATED_ARTICLES_EVERY_HEADINGS = 3;

export const ViewerMarkdown: React.FC<IViewerMarkdownProps> = (
  {
    store: {dynaCMS: {state: {settings: {defaultArticleFontFamilyGeneral}}}},
    article,
    article: {fontFamilyGeneral: articleFontFamily},
    content: {
      font,
      headingFirstLetter,
      markdown,
    },
    contentSpacing,
    viewWidthType,
  },
) => {
  const content = useMemo<ReactElement[]>(() => {
    const content: ReactElement[] = [];

    let key = 0;
    let markdownContentNo = 0;
    let markdownContent = "";
    let headings = 0;

    const pushAnyCollectedMarkdownContent = () => {
      if (!markdownContent) return;
      content.push(
        <Box
          key={key++}
          sx={{
            ...getContentPaddingSx(0),
            maxWidth: viewWidthType === EViewWidthType.EASY_READ ? 640 : undefined,
            "> div > div > p:first-of-type:first-letter":
              headingFirstLetter && markdownContentNo === 0
                ? sxHeadingLetter as any
                : undefined,
            "a": {color: theme => theme.palette.info.main},
          }}
        >
          <MarkdownViewer markdownText={markdownContent}/>
        </Box>,
      );
      markdownContentNo++;
      markdownContent = "";
    };

    markdown
      .split('\n')
      .map(line => line.trim())
      .forEach(_line => {
        const line = clearDoubleSpaces(_line);
        if (line.startsWith('#')) {
          pushAnyCollectedMarkdownContent();
          if (headings > 0 && headings % RELATED_ARTICLES_EVERY_HEADINGS === 0) {
            content.push(
              <DynaCMSRelatedArticles
                key={key++}
                h={2}
                topBottomSpacing={0}
                loadPublishedBeforeSourceArticle={false}
                sourceArticle={article}
                format={
                  headings % 2 === 0
                    ? ERelatedArticlesFormat.TINY_SMALL_PHOTO_TEXT
                    : ERelatedArticlesFormat.TWO_READ_MORE_INLINE
                }
              />,
            );
          }
          content.push(
            <ViewerHeading
              key={key++}
              article={article}
              content={{
                ...getDefaultDynaCMSBlockHeader(),
                heading: line.indexOf(" ") as any,
                text: line.substring(line.indexOf(" ")).trim(),
              }}
            />,
          );
          headings++;
        }
        else if (line.startsWith('>')) {
          pushAnyCollectedMarkdownContent();
          content.push(
            <ViewerQuote
              key={key++}
              content={{
                ...getDefaultDynaCMSBlockQuote(),
                main: line.substring(line.indexOf(" ")).trim(),
              }}
            />,
          );
        }
        else {
          markdownContent += line + '\n';
        }
      });

    pushAnyCollectedMarkdownContent();

    return content;
  }, [markdown]);

  return (
    <DynaCMSFont
      dataComponentName="ViewerMarkdown"
      font={[
        defaultArticleFontFamilyGeneral,
        articleFontFamily,
        font,
      ]}
      sx={{
        '> *': {
          marginTop: theme => theme.spacing(contentSpacing),
          marginBottom: theme => theme.spacing(contentSpacing),
        },
      }}
    >
      {content}
    </DynaCMSFont>
  );
};
