import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlDateTimeFormatterProps} from "./DynaIntlDateTimeFormatter";

const DynaIntlDateTimeFormatterLazy = lazy(() => import("./DynaIntlDateTimeFormatter"));

export const DynaIntlDateTimeFormatter = connect((props: IDynaIntlDateTimeFormatterProps) => (
  <LazyLoadComponent>
    <DynaIntlDateTimeFormatterLazy {...props}/>
  </LazyLoadComponent>
));
