import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IDynaIntlLanguagePickerProps} from "./DynaIntlLanguagePicker";
import {TObject} from "utils-library/dist/typescript";

const DynaIntlLanguagePickerLazy: any = lazy(() => import("./DynaIntlLanguagePicker"));

export const DynaIntlLanguagePicker = <TData extends TObject = any,>(props: IDynaIntlLanguagePickerProps<TData>) => (
  <LazyLoadComponent>
    <DynaIntlLanguagePickerLazy {...props}/>
  </LazyLoadComponent>
);
