import {
  API_PATH_ApiDynaIntlTranslationsGet,
  IApiDynaIntlTranslationsGetRequest,
  IApiDynaIntlTranslationsGetResponse,
} from "server-app/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";

export const apiDynaIntlTranslationsGet = async (
  {
    languageId,
    countryId,
    tks,
  }: IApiDynaIntlTranslationsGetRequest,
  ignoreCache?: boolean,
): Promise<IApiDynaIntlTranslationsGetResponse> => {
  if (ignoreCache) Object.keys(cache).forEach(key=>delete cache[key]);

  const cachedTranslations: Record<string, string> = {};
  for (const tk of tks) {
    const value = getCacheValue({
      languageId,
      countryId,
      tk,
    });
    if (value) cachedTranslations[tk] = value;
  }

  const loadTks = tks.filter(tk => !cachedTranslations[tk]);
  if (!loadTks.length) return {translations: cachedTranslations};

  const {translations: loadedTranslations} = await apiFetch.request<IApiDynaIntlTranslationsGetRequest, null, IApiDynaIntlTranslationsGetResponse>({
    method: 'GET',
    path: API_PATH_ApiDynaIntlTranslationsGet,
    query: {
      languageId,
      countryId,
      tks: loadTks,
    },
  });

  for (const [tk, value] of Object.entries(loadedTranslations)) {
    setCacheValue({
      countryId,
      languageId,
      tk,
      value,
    });
  }

  return {
    translations: {
      ...cachedTranslations,
      ...loadedTranslations,
    },
  };
};

const cache: Record<string, string> = {};

const getCacheKey = (
  {
    countryId,
    languageId,
    tk,
  }: {
    countryId: string;
    languageId: string;
    tk: string;
  },
) => [
  countryId,
  languageId,
  tk,
].join('/');

const setCacheValue = (
  {
    countryId,
    languageId,
    tk,
    value,
  }: {
    countryId: string;
    languageId: string;
    tk: string;
    value: string;
  },
): void => {
  const cacheKey = getCacheKey({
    countryId,
    languageId,
    tk,
  });
  cache[cacheKey] = value;
};

const getCacheValue = (
  args: {
    countryId: string;
    languageId: string;
    tk: string;
  },
): string => {
  return cache[getCacheKey(args)] || "";
};
